<template>
  <v-btn v-bind="$attrs" v-on="$listeners" :color="color" :depressed="depressed" :tile="tile">
    <slot/>
  </v-btn>
</template>

<script>
export default {
  name: 'KBtn',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    depressed: {
      type: Boolean,
      default: true,
    },
    tile: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
