import Model from './model';

class ProductCategory extends Model {
  id = 0;
  name = '';
  image = null;

  mapForRequest() {
    return {
      name: this.name,
      image: this.image instanceof Blob ? this.image : typeof this.image === 'undefined' ? null : undefined,
    };
  }
}

export default ProductCategory;
