import { get, post, put } from '@/application/api/implementations/app';
import { getPaginated } from '@/application/api/implementations/app/wrapper.js';

export const index = (clientId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`client/${clientId}/product-category`, page, perPage, search, sortBy, descending, params);

export const autocomplete = (clientId, page, perPage, search, sortBy, descending, params) =>
  getPaginated(`client/${clientId}/product-category`, page, perPage, search, sortBy, descending, params);

export const show = (categoryId) => get(`product-category/${categoryId}`);

export const create = (clientId, placement) => post(`client/${clientId}/product-category`, placement);

export const update = (placement) => put(`product-category/${placement.id}`, placement);
