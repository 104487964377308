<template>
  <div>
    <KCrudLayout :search.sync="searchQuery">
      <template #header>{{ $t('productCategory.pageTitle') }}</template>
      <template #flow-actions>
        <v-btn color="primary" depressed tile @click="handleOpenCreateDialog">
          {{ $t('productCategory.create') }}
        </v-btn>
        <ProductCategoryForm v-model="createDialog"
                             :request="createRequest"
                             :title="$t('productCategory.create')"
                             :values="createFormValues"
                             @change="$refs.table.reload()"/>
      </template>
      <template #view.list>
        <k-crud-table ref="table"
                      :headers="crudHeaders"
                      :index-request="indexRequest"
                      :search="searchQuery"
                      language-prefix="productCategory.fields"
                      resource="shift"
                      @click:edit="openUpdate"
                      @click:row="openUpdate">
        </k-crud-table>
      </template>
    </KCrudLayout>

    <ProductCategoryForm v-model="updateDialog"
                         :request="updateRequest"
                         :title="$t('productCategory.update')"
                         :values="updateFormValues"
                         is-update-form
                         @change="$refs.table.reload()"/>
  </div>
</template>

<script lang="js">
import eventBus from '@/application/eventBus.js';
import KCrudTable from '@/components/crud/KCrudTable.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update } from './../api/productCategory.js';
import ProductCategory from '@/application/models/ProductCategory.js';
import { mapGetters } from 'vuex';
import ProductCategoryForm from '@/modules/product/components/ProductCategoryForm.vue';

export default {
  name: 'ProductCategoryResource',
  components: {
    ProductCategoryForm,
    KCrudTable,
    KCrudLayout,
  },
  data() {
    return {
      searchQuery: '',
      createDialog: false,
      createFormValues: new ProductCategory(),
      dialogOpen: false,
      updateDialog: false,
      updateFormValues: new ProductCategory(),
    };
  },
  computed: {
    ...mapGetters('settings', { currentClientId: 'getCurrentClientId' }),
    crudHeaders() {
      return [
        {
          value: 'name',
          language: 'name',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'productCategory.index' },
        text: this.$t('productCategory.pageTitle'),
      },
    ]);
  },
  methods: {
    handleOpenCreateDialog() {
      this.createFormValues = new ProductCategory();
      this.createDialog = true;
    },
    createRequest() {
      return create(this.currentClientId, this.createFormValues);
    },
    updateRequest() {
      return update(this.updateFormValues);
    },
    indexRequest(...arg) {
      return index(this.currentClientId, ...arg);
    },
    async openUpdate(item) {
      this.updateFormValues = new ProductCategory();
      const employerShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(employerShowRequest.data.data);
      this.updateDialog = true;
    },
  },
};
</script>
