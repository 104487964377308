<template>
  <k-field-group language-prefix="productCategory.fields">
    <k-form-dialog v-bind="$attrs" v-on="$listeners" :panels="panels">
      <template #panel.0>
        <v-container class="pa-0">
          <v-row class="fill-height">
            <v-col cols="6">
              <form-sheet>
                <KTextField v-model="values.name" field="name" required/>
                <KFileField v-model="values.image" field="image" :min-height="!values.image && '256px'"/>
              </form-sheet>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import KFileField from '@/components/crud/fields/KFileField.vue';
import FormSheet from '@/components/FormSheet.vue';

export default {
  name: 'ProductCategoryForm',
  components: {
    FormSheet,
    KFileField,
    KFieldGroup,
    KTextField,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
    },
  },
  computed: {
    panels() {
      return [
        { name: this.$t('global.defaultTabName') },
      ];
    },
  },
};
</script>
